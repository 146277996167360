<template>
  <div class="row mt-10 export-plist">
    <!-- <modal :adaptive="true" height="auto" name="open_to_settings_table" width="300">
      <div class="row mx-0 py-7">
        <div class="col-12 row mx-0 py-2 navy-blue-border-bottom-1px text-xl font-weight-bold">
          <div class="col-6 text-center">{{ $t('general.colum_name') }}</div>
          <div class="col-6 text-center">{{ $t('general.add') }}</div>
        </div>
        <div class="col-12  mx-0 py-2 text-xl">
          <draggable v-model="fieldsToViewEdit" v-bind="dragOptions" :move="onMove" class="list-group py-4" tag="ul"
            @end="isDragging=false" @start="isDragging=true">
            <transition-group :name="'flip-list'" type="transition">
              <div v-for="element in fieldsToViewEdit" :key="element.sort"
                class="col-12 list-group-item w-300px d-flex justify-content-between ">
                <span class="col-6"> {{ $t(element.name) }}</span>

                <span class="col-6 d-flex justify-content-center">
                  <input v-model="element.type_status" style="font-size: 10px " type="checkbox"></span>
              </div>
            </transition-group>
          </draggable>
          <button class="btn btn-sm btn-outline-danger p-2 btn-pill font-weight-bold cursor-pointer mr-5"
            @click="closeModal">
            {{ $t('esc.new_send_close').toUpperCase() }}
          </button>
          <button class="btn btn-sm btn-outline-primary btn-pill p-2 font-weight-bold cursor-pointer"
            @click="onSubmitToSaveSettingTable">{{
            $t('esc.new_send')
            }}
          </button>
        </div>

      </div>
    </modal> -->

    <div v-if="!selectedItem" class="col-12">
      <div id="filter-card" class="row py-0 px-0" v-if="filters">
        <div
          class="col-12 py-4"
          style="
            border: 1px solid #0b5a85;
            background-color: #f3f6f9;
            border-radius: 12px;
          "
        >
          <form @submit.prevent="filterResult()">
            <div class="row align-items-end py-0 my-0 mx-0">
              <div class="col-lg-7 col-md-3 col-sm-12 my-2 row px-0 mx-0">
                <input-filter
                  :helper-text="$t('general.search')"
                  :model.sync="filters.search"
                  :placeholder="$t('general.search_filter')"
                  title="general.search"
                ></input-filter>
              </div>
              <div class="col-12 row mx-0 px-0">
                <div class="col-6 my-2">
                  <date-filter
                    :helper-text="$t('general.start_date')"
                    :model.sync="filters.start_date"
                    name="start_time"
                    title="general.start_date"
                  >
                  </date-filter>
                </div>
                <div class="col-6 my-2">
                  <date-filter
                    :helper-text="$t('general.end_date')"
                    :model.sync="filters.end_date"
                    name="end_date"
                    title="general.end_date"
                  >
                  </date-filter>
                </div>
              </div>

              <div class="col-12 row mx-0 px-0" style="margin: 0 !important">
                <div class="col-6 my-2">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                    @click="filterResult()"
                  >
                    {{ $t("general.filter") }}
                  </button>
                </div>
                <div class="col-6 my-2">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                    @click="resetFilters"
                  >
                    {{ $t("general.clear") }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div v-if="!selectedItem" class="col-12 mx-0 p-0 mt-10">
      <dashboard-box :title="$t('general.following_export')">
        <template v-slot:preview>
          <!-- <div class="fixed" style="position: fixed !important; top: 220px; right: 15px;">
            <div class="w-60px h-60px" @click="openPopToSettingTable">
              <span v-html="getIconByKey('icons.waybill.edit', {
               class: 'w-40px h-40px d-inline-block object-fill cursor-pointer'
              })">
              </span>
            </div>
          </div> -->
          <div class="export-plist-table">
            <data-table
              :bordered="false"
              :borderless="true"
              :fields.sync="tableFields"
              :hover="false"
              :items="itemsProxy"
              :no-border-collapse="true"
              :outlined="false"
              :page.sync="page"
              :perPage.sync="per_page"
              :striped="false"
              :tableParentFields="[]"
              @hitBottom="onHitBottom"
              @rowClicked="onRowClicked"
              :infiniteId="infiniteId"
              :sortBy.sync="order"
              :sortDesc.sync="sort"
            >
            </data-table>
          </div>

          <div v-if="total_quantity" class="row">
            <div class="col-12">
              <span class="h4 float-right mr-4">
                <b>{{ $t("packing_list.total_quantity") }} </b>: {{ total_quantity }} /
                <b>{{ $t("packing_list.box_quantity") }}:</b>
                {{ total_box_quantity }}</span
              >
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>

    <div v-if="selectedItem" class="col-12 mt-10 mx-0 p-0">
      <KTCard ref="preview" v-bind:example="true">
        <template v-slot:body>
          <div class="plist-table">
            <table
              id="my-table"
              aria-busy="false"
              aria-colcount="13"
              class="table-responsive b-table table-borderless b-table-no-border-collapse table-''"
              role="table"
            >
              <thead class="thead-none" role="rowgroup">
                <tr></tr>
                <tr class="" role="row">
                  <th class="text-center font-size-sm">
                    <div>
                      <span>{{ $t("packing_list.customer_name") }}</span>
                    </div>
                  </th>

                  <th class="text-center font-size-sm">
                    <div>
                      <span>{{ $t("packing_list.supplier_company_name") }}</span>
                    </div>
                  </th>

                  <th class="text-center font-size-sm">
                    <div>
                      <span>{{ $t("packing_list.order_number") }}</span>
                    </div>
                  </th>

                  <th class="text-center font-size-sm">
                    <div>
                      <span>{{ $t("packing_list.po_number") }}</span>
                    </div>
                  </th>

                  <th class="text-center font-size-sm">
                    <div>
                      <span>{{ $t("packing_list.color_name") }}</span>
                    </div>
                  </th>

                  <th class="text-center font-size-sm">
                    <div>
                      <span>{{ $t("packing_list.color_number") }}</span>
                    </div>
                  </th>

                  <th class="text-center font-size-sm">
                    <div>
                      <span>{{ $t("packing_list.body_size") }}</span>
                    </div>
                  </th>

                  <th class="text-center font-size-sm" style="width: 95px">
                    <div>
                      <span>{{ $t("packing_list.request_quantity") }}</span>
                    </div>
                  </th>

                  <th class="text-center font-size-sm">
                    <div>
                      <span>{{ $t("packing_list.d_any") }}</span>
                    </div>
                  </th>

                  <th class="text-center font-size-sm">
                    <div>
                      <span>{{ $t("packing_list.box_quantity") }}</span>
                    </div>
                  </th>

                  <th class="text-center font-size-sm">
                    <div>
                      <span>{{ $t("packing_list.in_package") }}</span>
                    </div>
                  </th>

                  <th class="text-center font-size-sm">
                    <div>
                      <span>{{ $t("packing_list.broken_parcel") }}</span>
                    </div>
                  </th>

                  <th class="text-center font-size-sm">
                    <div>
                      <span>{{ $t("packing_list.box_weight") }}</span>
                    </div>
                  </th>

                  <th class="text-center font-size-sm">
                    <div>
                      <span>{{ $t("packing_list.total") }}</span>
                    </div>
                  </th>
                  <th class="text-center font-size-sm">
                    <div>
                      <span
                        >{{ $t("packing_list.total") }} +
                        {{ $t("packing_list.broken_parcel") }}<i class="text-danger"></i
                      ></span>
                    </div>
                  </th>

                  <th class="text-center font-size-sm">
                    <div>
                      <span>{{ $t("packing_list.document_or_image_upload") }}</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody role="rowgroup">
                <template v-for="(item, index) in orderInformations">
                  <tr class="b-table-empty-row text-center" role="row">
                    <td class="" role="cell">
                      <span class="text-sm py-1 px-1 text-center w-100" type="text">
                        {{ item.customer_name }}
                      </span>
                    </td>
                    <td class="" role="cell">
                      <span class="text-sm py-1 px-1 text-center w-100" type="text">
                        {{ item.supplier_company }}
                      </span>
                    </td>
                    <td class="" role="cell">
                      <span class="text-sm py-1 px-1 text-center w-100" type="text">
                        {{ item.order_number }}
                      </span>
                    </td>
                    <td class="" role="cell">
                      <span class="text-sm py-1 px-1 text-center w-100" type="text">
                        {{ item.po_number }}
                      </span>
                    </td>
                    <td class="" role="cell">
                      <span class="text-sm py-1 px-1 text-center w-100" type="text">
                        {{ item.color_name }}
                      </span>
                    </td>
                    <td class="" role="cell">
                      <span class="text-sm py-1 px-1 text-center w-100" type="text">
                        {{ item.color_number }}
                      </span>
                    </td>
                    <td class="" role="cell">
                      <span class="text-sm py-1 px-1 text-center w-100" type="text">
                        {{ item.body_size }}
                      </span>
                    </td>
                    <td class="" role="cell">
                      <span class="text-sm py-1 px-1 text-center w-100" type="text">
                        {{ item.amount }}
                      </span>
                    </td>
                    <td class="" role="cell">
                      <span class="text-sm py-1 px-1 text-center w-100" type="text">
                        {{ item.d_any }}
                      </span>
                    </td>
                    <td class="" role="cell">
                      <span class="text-sm py-1 px-1 text-center w-100" type="text">
                        {{ item.box_quantity }}
                      </span>
                    </td>
                    <td class="" role="cell">
                      <span class="text-sm py-1 px-1 text-center w-100" type="text">
                        {{ item.broken_parcel }}
                      </span>
                    </td>
                    <td class="" role="cell">
                      <span class="text-sm py-1 px-1 text-center w-100" type="text">
                        {{ item.in_package }}
                      </span>
                    </td>
                    <td class="" role="cell">
                      <span class="text-sm py-1 px-1 text-center w-100" type="text">
                        {{ item.box_weight }}
                      </span>
                    </td>
                    <td class="" role="cell">
                      <span
                        class="flex-grow-1 text-sm py-1 px-1 text-center w-100"
                        type="text"
                      >
                        {{ item.upload_date }}
                      </span>
                    </td>
                    <td class="" role="cell">
                      <span class="text-sm py-1 px-1 text-center w-100" type="text">
                        {{
                          Number(item.box_quantity) * Number(item.in_package) +
                          Number(item.broken_parcel)
                        }}
                      </span>
                    </td>
                    <td class="" role="cell">
                      <span class="text-sm py-1 px-1 text-center w-100" type="text">
                        {{
                          item.broken_parcel > 0
                            ? Number(item.box_quantity) + 1
                            : item.box_quantity
                        }}
                      </span>
                    </td>

                    <td class="text-center" role="cell">
                      <img
                        v-show="item.image"
                        :id="`preview-content-image-${index}`"
                        alt=""
                        class="w-50px h-50px object-cover border-15px navy-blue-border-1px cursor-pointer"
                      />
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>

          <div class="col-12 d-flex justify-content-center mt-5 mx-0 p-0">
            <button
              class="btn btn-sm btn-outline-secondary btn-pill mb-2 mx-2"
              type="button"
              @click="cancelClick"
            >
              <i class="fas fa-reply font-size-sm"></i>
              {{ $t("general.back") }}
            </button>

            <button
              class="btn btn-sm btn-outline-danger btn-pill mb-2 mx-2"
              type="button"
              v-if="isUserGranted('PackingList', ['delete'], false)"
              @click="deleteClick"
            >
              <i class="fas fa-trash font-size-sm"></i>
              {{ $t("general.delete") }}
            </button>

            <button
              class="btn btn-sm btn-outline-info btn-pill mb-2 mx-2"
              type="button"
              v-if="isUserGranted('Export', ['update'], false)"
              @click="onConvertInvoice"
            >
              <i class="fas fa-share font-size-sm"></i>
              {{ $t("general.convert_to_invoice") }}
            </button>

            <button
              class="btn btn-sm btn-outline-primary btn-pill mb-2 mx-2"
              type="button"
              @click="onClickEdit"
              v-if="isUserGranted('Export', ['update'], false)"
            >
              <i class="fas fa-pen font-size-sm"></i>
              {{ $t("general.edit") }}
            </button>

            <button
              class="btn btn-sm btn-outline-primary btn-pill mb-2 mx-2"
              type="button"
              @click="onClickEditPackingList"
              v-if="isValidUpdate"
            >
              <i class="fas fa-pen font-size-sm"></i>
              {{ $t("general.edit_packing_list") }}
            </button>
          </div>
        </template>
      </KTCard>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";

// COMPONENTS
import DashboardBox from "@/assets/components/DashboardBox";
import DataTable from "@/assets/components/dataTable/DataTable";
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";

import {
  CREATE_ITEM,
  ERROR,
  GET_ITEM_DETAIL_BY_ID,
  GET_ITEMS,
  HANDLE_INFINITE_SCROLL,
  LOADING,
  SET_ERROR,
  SET_ITEMS,
  SET_LOADING,
  SUCCESS,
  UPDATE_ITEM_BY_ID,
  FILTER,
  SET_FILTER,
  DELETE_ITEM_BY_ID,
} from "@/core/services/store/packing-list/packing_list.module";

import { LARAVEL_DATE_FORMAT, MOMENT_SYSTEM_DATE_FORMAT } from "@/core/config/constant";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import KTCard from "@/view/content/Card.vue";
import * as _ from "lodash";
import draggable from "vuedraggable";
import $ from "jquery";
import {FOLLOWING_EXPORT_PACKING_LIST_INDEX_END_DATE_STORAGE_NAME, FOLLOWING_EXPORT_PACKING_LIST_INDEX_START_DATE_STORAGE_NAME} from "@/core/storage/storage-names"

export default {
  name: "PackingListIndex",
  components: {
    CustomMultiSelect,
    SelectFilter,
    InputFilter,
    DataTable,
    DashboardBox,
    ButtonWithIcon,
    DateFilter,
    KTCard,
    draggable,
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      error: ERROR,
      success: SUCCESS,
      packing: CREATE_ITEM,
      filter: FILTER,
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      },
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost",
      };
    },
    listString() {
      return JSON.stringify(this.list, null, 2);
    },
    tableFields() {
      let fields = [];
      let self = this;
      for (let [key, value] of Object.entries(self.modelTableItem[0].table_fields)) {
        if (value.status === true) {
          let options = [];
          if (key === "status") {
            for (const [key, value] of Object.entries(this.sampleStatuses)) {
              options.push({
                value: key,
                text: value,
              });
            }
          }
          fields.push({
            key: key,
            value: this.filter && this.filter.hasOwnProperty(key) ? this.filter[key] : "",
            label: this.$t(value.name),
            sortable: value.status,
            sort: value.sort,
            class: "text-center border border-top-0 border-left-0",
            tdClass: "cursor-pointer",
            searchable: value.searchable,
            searchable_type: value.searchable_type,
            searchable_options: options,
          });
        }
      }
      return _.sortBy(fields, ["sort"]);
    },
    itemsProxy: {
      get() {
        let results = [];
        if (!this.items || this.items.data === null || this.items.data === undefined)
          return results;

        let items = this.items;

        this.total_quantity = items.total;
        this.total_box_quantity = items.box_quantity;

        if (items.data) {
          items.data.forEach((item) => {
            let tmpItem = {
              id: item.id,
              date: moment(item.created_at).format(MOMENT_SYSTEM_DATE_FORMAT),
              order_number: item.orderable ? item.orderable.order_number : "",
              customer_name:
                item.orderable && item.orderable.company
                  ? item.orderable.company.name
                  : "-",
              supplier_company: item.supplier_company ? item.supplier_company.name : "-",
              order_id: item.orderable ? item.orderable.id : "",
              actions: { get: true },
            };

            let bodySizeArray = [];
            let colorNameArray = [];
            let uploadDateArray = [];
            let colorNumberArray = [];
            let totalAmount = 0;
            let requestedAmount = 0;
            let totalWeight = 0;

            item.data.forEach((subItem, index) => {
              let uploadDate =
                typeof subItem.upload_date !== "undefined"
                  ? moment(subItem.upload_date).format(MOMENT_SYSTEM_DATE_FORMAT)
                  : this.$t("general.not_exist ");
              uploadDateArray.push(
                "(" +
                  this.$t("following.size") +
                  ":" +
                  subItem.body_size +
                  "/" +
                  this.$t("general.date") +
                  ":" +
                  uploadDate +
                  ")"
              );

              bodySizeArray.push(subItem.body_size);
              totalAmount +=
                Number(subItem.in_package || 0) * Number(subItem.box_quantity || 0) +
                Number(subItem.broken_parcel || 0);
              totalWeight += Number(subItem.box_weight || 0);
              requestedAmount += Number(subItem.amount || 0);

              colorNameArray.push(subItem.color_name);
              colorNumberArray.push(subItem.color_number);
              tmpItem.box_weight = totalWeight;
              tmpItem.amount = requestedAmount;
              tmpItem.total = totalAmount;
              tmpItem.body_size = bodySizeArray.join(",");
              tmpItem.upload_date = uploadDateArray.join(",");

              tmpItem.po_number = item.orderable ? item.orderable.po_number : "";
              tmpItem.order_size_and_color_id = subItem.order_size_and_color_id;

              tmpItem.status = subItem.status;
              tmpItem.createdAt = subItem.created_at
                ? moment(subItem.created_at, LARAVEL_DATE_FORMAT).format(
                    MOMENT_SYSTEM_DATE_FORMAT
                  )
                : "";
            });

            tmpItem.color_number = _.uniq(colorNumberArray).join("-");
            tmpItem.color_name = _.uniq(colorNameArray).join("-");

            results.push(tmpItem);
          });
        }

        return results;
      },
      set(value) {
        // this.setItems(value);
      },
    },
    isValidUpdate() {
      if (!this.isUserGranted("Export", ["update"], false)) {
        return false;
      }
      if (!this.isUserGranted("Staff", ["followingExport", "superAdmin"], false)) {
        return false;
      }
      return true;
    },
  },
  data() {
    return {
      // Filters & pagination
      infiniteId: 1,
      editable: true,
      isDragging: false,
      delayedDragging: false,
      sampleStatuses: [],
      fieldsToViewEdit: [],
      url: "api/exports/packing-list",
      orderUrl: "api/orders",
      search: "",
      page: 1,
      per_page: 10,
      total_quantity: 0,
      total_box_quantity: 0,
      startDate: moment().subtract(30, "d"),
      endDate: moment().add(1, "d"),
      items: [],
      sort: "desc",
      order: "id",
      modelTableItem: [
        {
          user_id: null,
          model_name: null,
          table_fields: {},
        },
      ],
      storeUrl: "api/time-sheets",
      escListUrl: "api/time-sheets",
      selectedItem: null,
      orderInformations: [],
      itemId: this.$route.params.packing_id,
      activeItem: null,
    };
  },
  methods: {
    ...mapMutations({
      setItems: SET_ITEMS,
      setError: SET_ERROR,
      setFilter: SET_FILTER,
    }),
    onChangeSearchFilter(key, value) {
      this.$set(this.filters, key, value);
    },
    getTableItem() {
      let self = this;
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/model_table",
          filters: {
            model_name: "following_export_packing_list",
          },
          acceptPromise: true,
          showLoading: false,
        })
        .then((result) => {
          if (result.status) {
            for (let i = 0; i < result.data.length; i++) {
              self.modelTableItem = result.data;
              self.modelTableItem[i].table_fields = JSON.parse(
                result.data[i].table_fields
              );
            }
            for (const [key, value] of Object.entries(
              self.modelTableItem[0].table_fields
            )) {
              self.fieldsToViewEdit.push({
                name: this.$t(value.name),
                sort: value.sort,
                type: value.type,
                type_status: value.status,
                key: key,
                searchable: !!value.searchable,
                searchable_type: value.searchable_type,
                searchable_options: value.searchable_options,
              });
              self.fieldsToViewEdit = _.sortBy(self.fieldsToViewEdit, ["sort"]);
            }
          }
        });
    },
    orderList() {
      this.modelTableItem[0].table_fields = this.modelTableItem[0].table_fields.sort(
        (one, two) => {
          return one.sort - two.sort;
        }
      );
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (!relatedElement || !relatedElement.status) && !draggedElement.status;
    },
    openPopToSettingTable() {
      this.$modal.show("open_to_settings_table");
    },
    closeModal() {
      this.$modal.hide("open_to_settings_table");
    },
    onSubmitToSaveSettingTable() {
      let self = this;
      let json = {};

      for (let i = 0; i < this.fieldsToViewEdit.length; i++) {
        json[this.fieldsToViewEdit[i].key] = {
          name: this.fieldsToViewEdit[i].name,
          type: this.fieldsToViewEdit[i].type,
          status: this.fieldsToViewEdit[i].type_status,
          sort: i,
          searchable: !!this.fieldsToViewEdit[i].searchable,
          searchable_type: this.fieldsToViewEdit[i].searchable_type,
        };
      }

      let contents = {
        model_name: "following_export_packing_list",
        table_fields: json,
      };
      let payload = {
        url: "api/model_table/" + this.modelTableItem[0].id,
        id: this.id,
        contents: contents,
      };
      this.$store.dispatch(UPDATE_ITEM_BY_ID, payload).then((result) => {
        if (result.status) {
          this.$modal.hide("open_to_settings_table");
          self.sweetAlertSuccess(self.$t("general.successfully_created"));
          this.fieldsToViewEdit = [];
          this.getTableItem();
        } else {
          self.sweetAlertError(self.$t("general.internal_error"));
        }
      });
    },
    fixItemsArray(items) {
      let results = [];
      let orderSizeAndColorIds = [];
      items.forEach((orderItem) => {
        orderItem.data.forEach((subItem, index) => {
          orderSizeAndColorIds.push(subItem.order_size_and_color_id.toString());
          results.push({
            id: orderItem.id,
            order_number: orderItem.orderable ? orderItem.orderable.order_number : "",
            customer_name:
              orderItem.orderable && orderItem.orderable.company
                ? orderItem.orderable.company.name
                : "",
            po_number: orderItem.orderable ? orderItem.orderable.po_number : "",
            order_id: orderItem.orderable ? orderItem.orderable.id : "",
            order_size_and_color_id: Number(subItem.order_size_and_color_id),
            d_any: subItem.d_any,
            color_number: subItem.color_number,
            color_name: subItem.color_name,
            body_size: subItem.body_size,
            box_quantity: subItem.box_quantity,
            broken_parcel: subItem.broken_parcel,
            in_package: subItem.in_package,
            upload_date:
              typeof subItem.upload_date !== "undefined"
                ? moment(subItem.upload_date).format(MOMENT_SYSTEM_DATE_FORMAT)
                : this.$t("general.not_exist "),
            amount: subItem.amount,
            total: subItem.in_package * subItem.box_quantity + subItem.broken_parcel,
            box_weight: subItem.box_weight,
            image: subItem.image,
            image_link: orderItem.image_link[index],
            image_link_column: true,
            status: subItem.status,
            supplier_company: orderItem.supplier_company
              ? orderItem.supplier_company.name
              : "-",
            createdAt: subItem.created_at
              ? moment(subItem.created_at, LARAVEL_DATE_FORMAT).format(
                  MOMENT_SYSTEM_DATE_FORMAT
                )
              : "",
            actions: { get: true },
          });
        });
      });

      return {
        results: results,
        orderSizeAndColorIds: orderSizeAndColorIds,
      };
    },
    onRowClicked(item) {
      let self = this;
      if (self.isUserGranted("Export", ["update"])) {
        let results = [];
        let orderSizeAndColorIds = [];
        let items = this.activeItem || this.items.data;
        if (items) {
          let methodResults = this.fixItemsArray(items);
          results = methodResults.results;
          orderSizeAndColorIds = methodResults.orderSizeAndColorIds;
        }

        self.orderInformations = results.filter(
          (subItem) =>
            +subItem.id === +item.id &&
            orderSizeAndColorIds.includes(subItem.order_size_and_color_id.toString())
        );
        self.selectedItem = item;
        setTimeout(() => {
          self.orderInformations.forEach((item, index) => {
            $(`#preview-content-image-${index}`).attr("src", item.image_link);
          });
        }, 100);
      }
    },
    onClickEdit() {
      let self = this;
      let item = self.selectedItem;
      this.sweetAlertConfirm(this.$t("general.are_you_sure"), function (self) {
        let payload = {
          url: "api/exports/set-packing-edit/" + item.id,
          id: item.id,
        };
        self.$store.dispatch(UPDATE_ITEM_BY_ID, payload).then(() => {
          self.selectedItem = null;

          if (self.itemId) {
            self.itemId = null;
          }

          setTimeout(self.onHitBottom, 500);
        });
      });
    },
    onClickEditPackingList() {
      let self = this;
      let item = self.selectedItem;
      if (!this.isUserGranted("Export", ["update"])) {
        return false;
      }
      if (!this.isUserGranted("Staff", ["followingExport", "superAdmin"])) {
        this.sweetAlertError("general.you_have_no_authorized_to_do_this_action");
        return;
      }
      this.$router.push({
        name: "following_export.packing_list.edit",
        params: { packing_id: item.id },
      });
    },
    deleteClick() {
      let self = this;
      if (self.isUserGranted("PackingList", ["delete"])) {
        self.sweetAlertConfirm(
          this.$t("general.are_you_sure"),
          function (self) {
            let payload = {
              url: "api/following-production/packing-list/" + self.itemId,
              id: self.itemId,
            };
            self.$store.dispatch(DELETE_ITEM_BY_ID, payload).then(() => {
              self.selectedItem = null;
              if (self.itemId) {
                self.itemId = null;
              }
              setTimeout(self.onHitBottom, 500);
            });
          },
          () => {}
        );
      }
    },
    onConvertInvoice() {
      let self = this;
      if (self.isUserGranted("Export", ["update"])) {
        let item = self.selectedItem;
        this.sweetAlertConfirm(this.$t("general.are_you_sure"), function (self) {
          let payload = {
            url: "api/exports/set-packing-convert-invoice/" + item.id,
            id: item.id,
          };
          self.$store.dispatch(UPDATE_ITEM_BY_ID, payload).then((response) => {
            if (response.status) {
              self.selectedItem = null;
              if (self.itemId) {
                self.itemId = null;
              }
              self
                .sweetAlertSuccess(self.$t("general.successfully_saved"))
                .then((res) => {
                  setTimeout(self.onHitBottom, 500);
                });
            } else {
              let data = response.data.response.data;
              self.sweetAlertError(self.$t(data.message));
            }
          });
        });
      }
    },
    cancelClick() {
      let self = this;
      this.selectedItem = null;
      if (this.itemId) {
        this.itemId = null;
        setTimeout(self.onHitBottom, 500);
      }
    },

    filterResult() {
      localStorage.setItem(FOLLOWING_EXPORT_PACKING_LIST_INDEX_END_DATE_STORAGE_NAME, moment(this.filters.start_date));
      localStorage.setItem(FOLLOWING_EXPORT_PACKING_LIST_INDEX_START_DATE_STORAGE_NAME, moment(this.filters.end_date));

      let self = this;
      this.page = 1;
      this.filters.page = 1;
      this.infiniteId++;
      this.onHitBottom();
      if (self.state) {
        setTimeout(() => {
          self.state.reset();
        }, 100);
      }
    },
    onHitBottom($state) {
      let self = this;
      if (self.isUserGranted("Export", ["viewAny"])) {
        let filters = this.filters ? _.cloneDeep(this.filters) : {};
        this.$set(
          filters,
          "start_date",
          moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
        );
        this.$set(
          filters,
          "end_date",
          moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
        );
        
        this.$set(filters, "order", this.order);
        this.$set(filters, "sort", this.sort);

        self.$store
          .dispatch(HANDLE_INFINITE_SCROLL, {
            filters: filters,
            url: self.url,
            $state: $state,
          })
          .then((result) => {
            if (result.status) {
              self.items = result.data;
              if (!(result.data && result.data.data && result.data.data.length)) {
                $state.complete();
              }
              if ($state) {
                $state.loaded();
              }
            } else {
              if ($state) {
                $state.complete();
              }
            }
          });
      }
    },
    resetFilters() {
      this.sort = "desc";
      this.order = "id";
      this.setFilter({
        start_date: moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT),
        end_date: moment().add(1, "d").format(LARAVEL_DATE_FORMAT),
        search: null,
        status_id: 25,
      });
    },
    getPackingListItem() {
      let self = this;
      let parameterPackingID = self.itemId;
      if (parameterPackingID) {
        let payload = {
          url: "api/following-production/packing-list/" + parameterPackingID,
          id: this.id,
          redirectRouteOnNotFound: null,
        };
        this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, payload).then((response) => {
          if (response.status) {
            let item = response.data;
            self.activeItem = [item];
            item.order_id = item.orderable_id;
            self.onRowClicked(item);
          }
        });
      }
    },
  },
  created() {
    this.$store.commit(SET_LOADING, false);
    this.$store.commit(SET_ITEMS, {});
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("menu.following_export"), route: "following_export.index" },
      { title: this.$t("menu.management.packing_list") },
    ]);

    if (this.isUserGranted("Export", ["viewAny"])) {
      this.getTableItem();

      if (this.itemId) {
        setTimeout(this.getPackingListItem, 500);
      } else {
        if (!this.filter) {
          this.resetFilters();
        }
        this.filters.end_date = localStorage.hasOwnProperty(FOLLOWING_EXPORT_PACKING_LIST_INDEX_START_DATE_STORAGE_NAME)
          ? moment(localStorage.getItem(FOLLOWING_EXPORT_PACKING_LIST_INDEX_START_DATE_STORAGE_NAME))
          : moment().add(1, 'd').format(LARAVEL_DATE_FORMAT);

        this.filters.start_date = localStorage.hasOwnProperty(FOLLOWING_EXPORT_PACKING_LIST_INDEX_END_DATE_STORAGE_NAME)
          ? moment(localStorage.getItem(FOLLOWING_EXPORT_PACKING_LIST_INDEX_END_DATE_STORAGE_NAME))
          : moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT);

        setTimeout(this.onHitBottom, 500);
      }
    }
  },
  watch: {
    sort() {
      this.filterResult();
    },
    order() {
      this.filterResult();
    },
  },
};
</script>

<style lang="scss">
/*Mobile Start*/
@import "@/assets/sass/mobile/following-export/packingListIndex.scss";
/*Mobile End*/
</style>
